.DatePickerInputWithRangeWrapper{
  width: 100%;
  position: relative;
  .input-element {
    padding-right: 3rem;
  }
  .bc-icon-clear{
    position: absolute;
    right: 4.4rem;
    top: 0.9rem;
    cursor: pointer;
    z-index: 9;
  }
}
