
@keyframes bell {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-20deg);
  }
  60% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.bell-button {
  -webkit-animation:bell 1s linear ;
  -moz-animation:bell 1s linear;
  animation:bell 1s linear;
}

  .notification {
  position: relative;
  .bell-wrapper {
    position: relative;
    margin-right: 0.5rem;
    .bell-count {
      color: #fff;
      background: #E64E48;
      font-size: 1rem;
      font-weight: 600;
      position: absolute;
      height: 1.6rem;
      min-width: 1.6rem;
      border-radius: 50%;
      padding: 0.2rem;
      top: 0.4rem;
      left: 2rem;
      text-align: center;
    }
  }
  .notification-popup {
    border-radius: 1rem;
    position: absolute;
    background: #ffffff;
    box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.05);
    right: 1.2rem;
    top: 3.5rem;
  }
}







