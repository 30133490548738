.notification-box-container {
  width: 40rem;
  position: relative;
  .loader-holder {
    position: absolute !important;
    margin: auto !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    z-index: 2;
    background: rgba(255,255,255,0.4);
  }
  .notification-box-title-container {
    position: relative;
    .notification-box-title {
      font-weight: 700;
      padding: 2rem;
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.16);
      text-align: center;
    }
    .notification-box-expand {
      position: absolute;
      top: 1.2rem;
      right: 0;
    }
  }
  .notification-box-actions {
    display: flex;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.16);
    padding: 0.6rem 0;
    .mark-all-read {
      margin-left: auto;
    }
    .notification-box-action-switcher {
      padding-top: 1rem;
      padding-left: 1.6rem;
    }
  }

  .notification-box {
    height: 40rem;
    overflow: auto;
    .notification-box-item {
      cursor: pointer;
      padding: 1.6rem;
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.16);
      &:hover {
        background: rgba(20, 115, 230, 0.08);
      }
      .notification-box-item-content {
        position: relative;
        display: flex;
        justify-content: space-between;
        .notification-box-item-text {
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          margin-right: 1rem;
        }
        .bc-icon-status-circle {
          color: #1473E6;
          line-height: 0.8;
          width: 2rem;
        }
        .bc-icon-success-fill {
          font-size: 1.5rem;
          line-height: 1.3;
          color: #2BC784;
        }
      }
      .notification-box-item-info {
        padding-bottom: 1rem;
        display: flex;
        .notification-box-item-info-left {
          display: flex;
          .notification-box-item-product {
            margin-right: 1rem;
          }
          .notification-box-item-aml-box {
            color: rgba(20, 115, 230, 1);
            background: rgba(0, 0, 0, 0.04);
            border: 0.1rem solid rgba(0, 0, 0, 0.1);
            border-radius: 0.3rem;
            padding: 0.3rem 0.6rem;
            font-weight: bold;
          }
        }
        .notification-box-item-info-right {
          margin-left: auto;
          .notification-box-item-date {
            font-weight: 600;
            color: rgba(0, 0, 0, 0.6);
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
  .notification-box-filter {
    min-width: 70rem;
    overflow: auto;
    display: flex;
    padding: 1.6rem;
    .notifications-filter-dropdown-item {
      width: 27rem;
      min-width: 21rem;
      margin-right: 1.2rem;
    }
    .DatePickerInputWithRangeWrapper {
      width: 27rem;
      min-width: 26rem;
    }
  }
}

.notification-box-container.expended {
  width: 104.7rem;
}


@media (max-width: 1300px) {
  .notification-box-container.expended {
    width: 80rem;
  }
}
