.settings-popup-container{
  .modal-body{
    padding: 0  !important;
  }
  .loader-holder {
    position: absolute !important;
    margin: auto !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    z-index: 2;
    background: rgba(255,255,255,0.4);
  }
}

