
/**
 * COLORS
 */
$header-height: 5.6;

$full-page-with-filter-large: calc(100vh - 38.1rem) !important;
$full-page-with-filter-large-empty: calc(100vh - 30.4rem) !important;
$full-page-with-filter-normal: calc(100vh - 38.1rem) !important;
$full-page-with-filter-normal-empty: calc(100vh - 30.4rem) !important;
$full-page-with-filter-small: calc(100vh - 38.1rem) !important;
$full-page-with-filter-small-empty: calc(100vh - 30.4rem) !important;

$full-page-with-filter-with-button-large: calc(100vh - 37.5rem) !important;
$full-page-with-filter-with-button-large-empty: calc(100vh - 29.8rem) !important;
$full-page-with-filter-with-button-normal: calc(100vh - 37.5rem) !important;
$full-page-with-filter-with-button-normal-empty: calc(100vh - 29.8rem) !important;
$full-page-with-filter-with-button-small: calc(100vh - 37.6rem) !important;
$full-page-with-filter-with-button-small-empty: calc(100vh - 29.8rem) !important;

$full-page-with-filter-without-search-large: calc(100vh - 30.1rem) !important;
$full-page-with-filter-without-search-large-empty: calc(100vh - 22.4rem) !important;
$full-page-with-filter-without-search-normal: calc(100vh - 30.1rem) !important;
$full-page-with-filter-without-search-normal-empty: calc(100vh - 22.4rem) !important;
$full-page-with-filter-without-search-small: calc(100vh - 30.2rem) !important;
$full-page-with-filter-without-search-small-empty: calc(100vh - 22.5rem) !important;

$full-page-without-filter-without-search-large: calc(100vh - 26.6rem) !important;
$full-page-without-filter-without-search-large-empty: calc(100vh - 19rem) !important;
$full-page-without-filter-without-search-normal: calc(100vh - 22.2rem) !important;
$full-page-without-filter-without-search-normal-empty: calc(100vh - 14.7rem) !important;
$full-page-without-filter-without-search-small: calc(131vh - 21.7rem) !important;
$full-page-without-filter-without-search-small-empty: calc(131vh - 14rem) !important;


$sub-header-height: 6rem;
$general-bet-purple:  #3d3d5f;
$content-bg: #f9fbfc;

$transition-duration: 1.75ms;

$accent-color-1: #1473e6;
$accent-color-2: #2bc784;
$accent-color-3: #e6af57;
$accent-color-4: #2A6BE6;

$common-txt-color-1: #3C4043;
$common-txt-color-2: #5d5d5d;
$common-txt-color-3: #ffffff;

$common-white-bg: #ffffff;
$common-black-bg: #000000;

$skeleton-content-bg: #f7f7f7;

$error-color: #e64e48;
$success-color: #5acc3f;
$info-color: #1473e6;

/**
 * Mixins
 */
@mixin moz-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #F2F2F2 transparent;
}
