@import "src/scss/variables/colors";

.view-mode-large{
  .full-screen-main-container {
    width: 100%;
    .home{
      min-height: 90vh !important;
    }
  }
}
.view-mode-normal{
  .full-screen-main-container {
    width: 100%;
  }
}
.view-mode-small{
  .full-screen-main-container {
    width: 100%;
  }
}
.main-wr {
  margin-top: calc(#{$header-height}rem ) !important;
  grid-area: bottom;
  overflow: hidden;
  &.full-screen{
    background: #f0f2f5;
    margin-top: 0 !important;
    .main-content{
      background: none !important;
    }
  }
  &.from-iframe{
    margin-top: 0 !important;
  }
}


.full-screen-main-container{
  width: 100%;
  grid-area: right;
  position: relative;
  overflow: hidden;
}
.main-content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 11fr;
  grid-template-rows: 12fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0;
  grid-template-areas: "left right";
  background: #f9fbfc;
  overflow: hidden;
}
.top-i-right-main-c{
  grid-area: top;
  position: relative;
  //overflow: hidden;
}
.scrollable-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.toaster-holder {
  z-index: 1000000 !important;
}
