@import "src/scss/variables/colors";
.top-aml-menu-bar{
  .m-dropdown-content{
    display: flex;
    .dropdown-header{
      margin: auto;
      display: flex;
      color: #ffffff;
      cursor: pointer;
      span{
        min-height: 1.6rem;
        font-size: 1.3rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
      .loader-holder{
        position: initial !important;
        background: none;
        i{
          margin-left: 0.3rem;
          width: 1.89rem;
          height: 1.7rem;
          font-size: 1.9rem;
        }
      }
      .arrow{
        margin-left: 0.3rem;
        width: 1.89rem;
        height: 0.5rem;
        font-size: 1.9rem;
      }
    }
  }

}
.menu-items{
  z-index: 299;
  top: calc(#{$header-height}rem ) !important;
  position: fixed;
}
