.alert-monitor-sub {
  .alert-monitor-content {
    .paper {
      border-radius: inherit !important;
      box-shadow: none !important;
    }
    .table-empty-holder {
      min-height: inherit !important;
    }
  }
}
.full-page-with-filter-without-search {
  .table-holder {
    max-height: inherit !important;
  }
  .content-empty {
    .ta-body {
      height: 90% !important;
      .table-empty-holder {
        height: 100%
      }
    }
  }
}
.view-mode-large {
  .aml-multi-account-review-modal-table {
    .DataList{
      table {
        tbody {
          min-height: 4rem !important;
          max-height: calc(100vh - 39rem) !important;
        }
      }
    }
  }
  .full-page-with-filter {
    .scroll-holder {
      height: $full-page-with-filter-large;
      max-height: $full-page-with-filter-large;
      min-height: $full-page-with-filter-large;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty {
      .scroll-holder {
        min-height: $full-page-with-filter-large-empty;
      }
    }
  }
  .full-page-with-filter-with-button {
    .scroll-holder {
      height: $full-page-with-filter-with-button-large;
      max-height: $full-page-with-filter-with-button-large;
      min-height: $full-page-with-filter-with-button-large;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty {
      .scroll-holder {
        min-height: $full-page-with-filter-with-button-large-empty;
      }
    }
  }
  .full-page-with-filter-without-search{
    .scroll-holder {
      height: $full-page-with-filter-without-search-large;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        height: $full-page-with-filter-without-search-large-empty;
      }
    }
  }
  .full-page-without-filter-without-search{
    .scroll-holder {
      height: $full-page-without-filter-without-search-large;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        min-height: $full-page-without-filter-without-search-large-empty;
      }
    }
  }
  .alert-monitor-sub {
    .alert-monitor-content {
      //height: calc(100vh - 28.5rem) !important;
      .table-holder {
        .scroll-holder {
          height: $full-page-with-tabs-large;
          .scroll-content {
            height: 100% !important;
          }
        }
      }
      .content-empty {
        .scroll-holder {
          min-height: $full-page-with-tabs-large-empty;
          .table-empty-holder {
            height: 100%;
          }
        }
      }
    }
  }
  .player-deep-view-tabs {
    height: calc(100vh - 21.1rem);
    overflow: auto;
  }
  .deep-view-tabs-table {
    .scroll-holder {
      height: calc(100vh - 32rem) !important;
    }
    .content-empty{
      .scroll-holder {
        height: calc(100vh - 32rem) !important;
      }
    }
  }
  .deep-view-tabs-table-pagination {
    .scroll-holder {
      height: calc(100vh - 45.7rem) !important;
    }
    .content-empty{
      .scroll-holder {
        height: calc(100vh - 38rem) !important;
      }
    }
  }
  #holder-portal-Container{
    .holder-content{
      height: calc(100vh - #{$header-height}rem - 0.3rem) !important;
    }
  }
}
.view-mode-normal {
  .aml-multi-account-review-modal-table {
    .DataList{
      table {
        tbody {
          min-height: 4rem !important;
          max-height: calc(100vh - 37rem) !important;
        }
      }
    }
  }
  #holder-portal-Container{
    .holder-content{
      height: calc(100vh - #{$header-height}rem) !important;
    }
  }
  .full-page-with-filter  {
    .scroll-holder {
      height: $full-page-with-filter-normal;
      max-height: $full-page-with-filter-normal;
      min-height: $full-page-with-filter-normal;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        min-height: $full-page-with-filter-normal-empty;
      }
    }
  }
  .full-page-with-filter-with-button  {
    .scroll-holder {
      height: $full-page-with-filter-with-button-normal;
      max-height: $full-page-with-filter-with-button-normal;
      min-height: $full-page-with-filter-with-button-normal;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        min-height: $full-page-with-filter-with-button-normal-empty;
      }
    }
  }
  .full-page-with-filter-without-search{
    .scroll-holder {
      height: $full-page-with-filter-without-search-normal;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        height: $full-page-with-filter-without-search-normal-empty;
      }
    }
  }
  .full-page-without-filter-without-search{
    .scroll-holder {
      height: $full-page-without-filter-without-search-normal;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        min-height: $full-page-without-filter-without-search-normal-empty;
      }
    }
  }
  .alert-monitor-sub {
    .alert-monitor-content {
      //height: calc(100vh - 28.4rem) !important;
      .table-holder {
        .scroll-holder {
          height: $full-page-with-tabs-normal;
        }
      }
      .content-empty {
        .scroll-holder {
          height: $full-page-with-tabs-normal-empty;
          .table-empty-holder {
            height: 100%;
          }
        }
      }
    }
  }
  .player-deep-view-tabs {
    height: calc(100vh - 21.1rem);
    overflow: auto;
  }
  .deep-view-tabs-table {
    .scroll-holder {
      height: calc(100vh - 34rem) !important;
    }
    .content-empty{
      .scroll-holder {
        height: calc(100vh - 34rem) !important;
      }
    }
  }
  .deep-view-tabs-table-pagination {
    .scroll-holder {
      height: calc(100vh - 41.8rem) !important;
    }
    .content-empty{
      .scroll-holder {
        height: calc(100vh - 34rem) !important;
      }
    }
  }
}
.view-mode-small {
  .aml-multi-account-review-modal-table {
    .DataList{
      table {
        tbody {
          min-height: 4rem !important;
          max-height: calc(100vh - 30rem) !important;
        }
      }
    }
  }
  #holder-portal-Container{
    .holder-content{
      height: calc(100vh - #{$header-height}rem - 0.3rem) !important;
    }
    .holder {
      height: calc(128vh - 0.7rem) !important;
    }
  }
  .full-page-with-filter  {
    .scroll-holder {
      height: $full-page-with-filter-small;
      max-height: $full-page-with-filter-small;
      min-height: $full-page-with-filter-small;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        min-height: $full-page-with-filter-small-empty;
        max-height: $full-page-with-filter-small-empty;
      }
    }
  }
  .full-page-with-filter-with-button  {
    .scroll-holder {
      height: $full-page-with-filter-with-button-small;
      max-height: $full-page-with-filter-with-button-small;
      min-height: $full-page-with-filter-with-button-small;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        min-height: $full-page-with-filter-with-button-small-empty;
        max-height: $full-page-with-filter-with-button-small-empty;
      }
    }
  }
  .full-page-with-filter-without-search {
    .scroll-holder {
      height: $full-page-with-filter-without-search-small;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        height: $full-page-with-filter-without-search-small-empty;
      }
    }
  }
  .full-page-without-filter-without-search{
    .scroll-holder {
      height: $full-page-without-filter-without-search-small;
      .scroll-content {
        height: 100% !important;
      }
    }
    .content-empty{
      .scroll-holder {
        min-height: $full-page-without-filter-without-search-small-empty;
      }
    }
  }
  .alert-monitor-sub {
    .alert-monitor-content {
      //height: calc(100vh - 28.9rem) !important;
      .table-holder {
          .scroll-holder {
            height: $full-page-with-tabs-small;
            .scroll-content {
              height: 100% !important;
            }
          }
        }
      .content-empty {
          .scroll-holder {
            height: $full-page-with-tabs-small-empty;
            .table-empty-holder {
              height: 100%;
            }
          }
        }
    }
  }
  .player-deep-view-tabs {
    height: calc(100vh - 19.1rem);
    overflow: auto;
  }
  .deep-view-tabs-table {
    .scroll-holder {
      height: calc(100vh - 31rem) !important;
    }
    .content-empty{
      .scroll-holder {
        height: calc(100vh - 30.9rem) !important;
      }
    }
  }
  .deep-view-tabs-table-pagination {
    .scroll-holder {
      height: 100vh !important;
    }
    .content-empty {
      .scroll-holder {
        height: 100vh !important;
      }
    }
  }
}
