.no-notification-setting {
  width: 40rem;
  .no-notification-setting-title {
    font-weight: 700;
    padding: 2rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.16);
    text-align: center;
  }
  .no-notification-setting-body {
    padding: 1.6rem 1rem;
    text-align: center;
    font-weight: 600;
    line-height: 2.1rem;
    font-size: 1.6rem;
    .notification-setting-link {
      color: #1473E6;
      cursor: pointer;
    }
  }
  .no-notification-setting-img {
    img {
      margin: auto;
      padding: 1rem;
    }
  }
}
