.apps{
  position: relative;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  cursor: pointer;
  [dir=ltr] & {
    border-right: 1.5rem solid transparent;
  }
  [dir=rtl] & {
    border-left: 1.5rem solid transparent;
  }

  &:last-child{
    border: none;
  }
  .apps-popup{
    border-radius: 2rem;
    position: absolute;
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.05);
    right: 1.2rem;
    top: 3.5rem;
    .apps-popup-line {
      height: 0.1rem;
      margin-top: 2rem;
      background-color: #e2e2e2;
      float: left;
      width: 100%;
    }
    .products{
      float: left;
      width: 9rem;
      margin: 1rem auto 1rem auto;
      .products-img{
        height: 5rem;
        width: 5rem;
        margin: 0 auto;
      }
      .apps-icon {
        width: 5rem;
        height: 5rem;
        margin: 0 1.6rem 0.5rem;
        border-radius: 1.5rem;
        background-color: rgba(155, 155, 155, 0.05);
        display: flex;
        img {
          margin: auto;
        }
      }
      .products-text{
        margin: 0.5rem 0 0;
        font-size: 1.2rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #3c4043;
      }
    }
  }
}
