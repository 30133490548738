.notifications-popup-container {
  .modal-body {
    padding-top: 0rem;
    min-height: 10rem;
  }
  .notifications-popup-item {
    margin-top: 1.6rem;
    .notifications-popup-label {
      display: flex;
      .notifications-popup-title {
        font-weight: 600;
      }
      .notifications-popup-line {
        height: 0.1rem;
        background: rgba(0, 0, 0, 0.16);
        flex: 1;
        margin-top: 0.9rem;
        margin-left: 1.6rem;
      }
    }
    .notifications-popup-dropdown-container {
      display: flex;
      gap: 1.6rem;
      margin-top: 1.6rem;
    }
  }
  .loader-holder {
    position: absolute !important;
    margin: auto !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    z-index: 2;
    background: rgba(255,255,255,0.4);
  }
}
