.top-menu-bar{
  margin-left: 2rem;
  .main-menu{
    height: 60%;
    margin: auto;
    .bc-navigation-menu_layer {
      a {
        pointer-events: none;
      }
    }
  }
}
