.choose-partner-modal{
  .modal-content {
   .modal-body{
     padding: 1.5rem 1.5rem 1.5rem 1.5rem !important;
     overflow: auto;
     max-height: calc(100vh - 50rem) !important;
   }
   margin-top: 10rem !important;
   button{
     &:first-child {
       .ellipsis-text{
         color: #3c4043;
       }
     }
   }
    .container{
      .view-mode-item-radio{
        margin-bottom: 1rem;
        label{
          color: #3c4043;
        }
      }
    }
  }
}
