@import "src/scss/variables/colors";

.loader-holder{
  &.inline{
    > .loader{
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  &.full{
    width: 100%;
    height: 100%;
    position: fixed;
    top: -6rem;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba($content-bg, 0.4);

  }
  &.center {
    top: 0;
  }
}
.loader-holder {
  position: absolute !important;
  margin: auto !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  z-index: 2;
  background: rgba(255,255,255,0.4);
}
@keyframes transparent-v {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes spinner {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
