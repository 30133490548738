.flex {
  display: flex
}

.f-ac {
  align-items: center;
}

.f-dc {
  flex-direction: column;
}

// justify content
.f-jsb {
  justify-content: space-between!important;
}

.f-jc {
  justify-content: center!important;
}

// wrap
.f-wrap {
  flex-wrap: wrap;
}

