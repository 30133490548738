.failedDataLoadingError {
  text-align: center;
  margin: auto;
  padding: 3rem;
  .failedDataLoadingError-icon {
    img {
      margin: auto;
    }
  }
  .failedDataLoadingError-title {
    font-size: 1.6rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #3c4043;
  }
  .failedDataLoadingError-text {
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #3c4043;
    max-width: 24rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  .failedDataLoadingError-button {
    margin-top: 2.6rem;
    &.failedDataLoadingError-close {
      margin-left: 2rem !important;
    }
    button {
      margin: auto;
    }
  }
}

.failedDataLoadingError.horizontal {
  padding: 0 3rem;
  .failedDataLoadingError-icon {
    display: inline-block;
    vertical-align: middle;
  }
  .failedDataLoadingError-title {
    display: inline-block;
    vertical-align: middle;
    padding-left: 1rem;
  }
  .failedDataLoadingError-button {
    display: inline-block;
    vertical-align: middle;
    padding-left: 2rem;
    margin-top: 0;
  }
}
