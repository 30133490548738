@import "./common/flex";
@import "./variables/fonts";
@import "./variables/colors";
@import "./variables/size";
@import "./common/viewMode";
.tooltip-c-p.s-default.top{
  z-index: 1000 !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.btn>*+* {
  margin: 0 0 0 0.7rem !important;
}
.bc-navigation-menu_layer {
  opacity: .7 !important;
}
.bc-navigation-menu_title-wrapper:last-child {
  .bc-navigation-menu_layer {
    opacity: 1 !important;
  }
}
.main-wr.full-screen {
  height: 100%;
}
.GeneUIProvider {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 11fr;
  grid-gap: 0;
  grid-template-areas:
        "top"
        "bottom";
  background: #f9fbfc;
}
.table-tooltip {
  .tooltip-content {
    background: #ffffff;
    color: inherit;
    border: 0.1rem solid #000000db
  }
}
.toaster-holder {
  z-index: 1000000 !important;
}
#_umbrella {
  @include openSans();
  width: 100%;
  height: 100%;
  //display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 11fr;
  grid-gap: 0;
  grid-template-areas: "top" "bottom";
  background: #f9fbfc;
}
.holder-body-c{
  .mobile-date-ranges-picker{
    width: 94% !important;
  }
  .accordion-holder{
    .mobile-date-ranges-picker{
      width: 100% !important;
    }
  }
}

.page {
  height: 100%;
  width: 100%;
  background-color: #f9fbfc;
}

html, body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: relative;
}

.top-reduce-header {
  top: 5.6rem
}

.left-banner-block {
  height: 100%;
  position: relative;
}

.btn-without-hover {
  background: none !important;
}

.clickable-link{
  color: var(--hero);
  font-weight: 600;
  cursor: pointer;
}

.left-main-container {
  grid-area: left;
  height: calc(100vh - #{$header-height}rem);
}

.pointer-events-none {
  pointer-events: none;
}

.main-wr {
  grid-area: bottom;
  position: relative;
}
.main-content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 11fr;
  grid-template-rows: 12fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0;
  grid-template-areas: "left right";
  background: #f9fbfc;
  overflow: hidden;
}
.right-main-container {
  grid-area: right;
  position: relative;
  padding-top: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  overflow-y: auto;
}

.modal-splash.light-background:not(.set-alert-wr){
    z-index: 999 !important;
}
.player-overlay{
  z-index: 9999 !important;
}
.overlay-back-drop{
  z-index: 999 !important;
}
.set-alert-wr{
  z-index: 99999 !important;
}

.inside-right-main-c{
  width: 100%;
  height: 100%;
}
.top-i-right-main-c{
  grid-area: top;
  position: relative;
}
.bottom-i-right-main-c{
  grid-area: bottom;
  position: relative;
}
.scrollable-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.popover-positioner{
  z-index: 9999 !important;
}
.alert-monitor-sub {
  .table-holder {
    .scroll-holder {
      .scroll-content {
        height: 100% !important;
      }
    }
  }
  .left-banner-block {
    height: 100%;
    position: relative;
  }

  .left-main-container {
    grid-area: left;
    height: calc(100vh - 21rem)
  }
}
.react-tiny-popover-container{
  z-index: 9999 !important;
  pointer-events: none;
}
.empty-component {
  height: 100%;
}

.table-header-divider {
  background-color: rgba(0, 0, 0, 0.16)
}
.from-to-block {
  .from-to-label {
    font-weight: 600;
  }
  .from-to-filter-section {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    .validatable-input:first-child {
      margin-right: 1.5rem;
    }

  }
}
.loader-holder {
  transform: none !important;
  left: 0 !important;
  top: 0 !important;
}

.tooltip-c-p {
  z-index: 99999 !important;
  .column-tooltip {
    .ellipsis-text {
      white-space: inherit !important;
    }
  }
}
