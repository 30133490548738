.menu{
  background: white;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0rem 0.1rem 0.9rem 0rem;
  .option-container{
    padding: 0.5rem 1.4rem;
    .option-texts {
      .option-title {
        a {
          position: relative;
          padding: 0;
          line-height: initial;
          display: flex;
        }
      }
    }
  }
}
