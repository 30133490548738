@font-face {
  font-family: 'Open Sans', sans-serif;;
  src: local('Open Sans Regular'), local('OpenSansRegular'),
  url('../../assets/fonts/OpenSans-Regular.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@mixin openSans() {
  font-family: 'Open Sans', sans-serif;
}
