@import "src/scss/variables/colors";
.view-mode-large{
  .header-fixed {
    width: 100vw;
  }
}
.view-mode-normal{
  .header-fixed {
    width: 100vw;
  }
}
.view-mode-small{
  .header-fixed {
    width: 100vw;
  }
}

.user-avatar-c.clickable {
  background: #fff !important;
  color: black !important;
}
.user-avatar-c.clickable:hover {
  box-shadow: 0 0 0 .3rem rgba(255, 255, 255, 0.2) !important;
}
.header-fixed {
  position: fixed !important;
  z-index: 1;
  width: 100%;
}

.header {
  height: $header-height + rem;
  padding: 0 2rem;
  background-color: #3d3d5f;
  grid-area: top;
  position: relative;
  z-index: 300;
}

.h-sides {
  display: table;
  height: 100%;

  &.left{
    [dir=ltr] & {
      float:left;
    }
    [dir=rtl] & {
      float:right;
    }
  }
  &.right{
    [dir=ltr] & {
      float:right;
    }
    [dir=rtl] & {
      float:left;
    }
  }

  &.center{
    position: absolute;
    top: 0;

    [dir=ltr] & {
      left: 50%;
      transform: translateX(-50%);
    }
    [dir=rtl] & {
      right: 50%;
      transform: translateX(50%);
    }
  }
}

.h-sides-content{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  &.left-content{
    display: flex;
  }
  .user-profile-c {
    color: var(--foreground-sc) !important;
  }
}

.h-logo-link{
  cursor: pointer;
}

.h-logo{
  display: block;
  height: 100%;
  width: 3rem;
}


.Divider {
  width: 0.1rem;
  height: 3.2rem;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 1.9rem;
}
.userProfile {
  padding-left: 1.5rem
}
.h-u-item{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  [dir=ltr] & {
    border-right: 1.5rem solid transparent;
  }
  [dir=rtl] & {
    border-left: 1.5rem solid transparent;
  }

  &:last-child{
    border: none;
  }
}
.h-user {
  .header-time {
    color: white;
    font-size: 1.4rem;
    align-items: center;
    font-weight: 600;
    white-space: nowrap;
    line-height: 3.2rem;
  }
}
.h-u-time{
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-size: 1.4rem;
  color: #ffffff;
  line-height: 3.2rem;

  [dir=ltr] & {
    padding-right: 1.5rem;
    border-right: 0.1rem solid rgba(#fff, 0.1);
  }
  [dir=rtl] & {
    padding-left: 1.5rem;
    border-left: 0.1rem solid rgba(#fff, 0.1);
  }
}

.h-u-icon{
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1.6rem;
  color: #ffffff;
  transition: color $transition-duration ease;
  cursor: pointer;

  &:hover{
    color:$accent-color-1;
  }
}
.x-icon {
  margin-right: 1rem;
}
.h-u-name-holder{
  display: block;
  height: 3.2rem;
  border: 0.3rem solid transparent;
  border-radius: 3.2rem;
  position: relative;
  background-color: rgba(#000, 0.16);
  transition: background-color $transition-duration ease;
  cursor: pointer;

  [dir=ltr] & {
    padding: 0 2rem 0 4.4rem;
  }
  [dir=rtl] & {
    padding: 0 4.4rem 0 2rem;
  }

  &:hover{
    background-color: $accent-color-1;

    .h-u-n-icon{
      color: $accent-color-1;
    }
  }
}

.h-u-icon-holder{
  display: inline-block;
  width: 2.6rem;
  height: 2.6rem;
  position: absolute;
  top: 0;
  border-radius: 50%;
  background-color: #ffffff;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.h-u-n-icon{
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 50%;
  font-size: 1.6rem;
  color: #3e3e55;
  transition: color $transition-duration ease;

  [dir=ltr] & {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  [dir=rtl] & {
    right: 50%;
    transform: translate(-50%, 50%);
  }
}

.h-u-name{
  display: block;
  font-weight: normal;
  font-size: 1.2rem;
  color: #ffffff;
  line-height: 2.8rem;
}
